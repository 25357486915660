// @ts-check
import React, { useState, useEffect } from 'react'
import tw, { css, styled } from 'twin.macro'
import { Global } from '@emotion/react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { Menu, MenuList, MenuButton, MenuLink } from '@reach/menu-button'
import { Lottie } from '@crello/react-lottie'
import * as animationMenu from '../animations/menu-3.json'
import filterLocale from '../components/utils/filter-locale'
import MobileMenu from './menu/mobile-menu'
/** Hook in to contextAPI */
import useCart from '../hooks/useCart'
import useTheme from '../hooks/theme'
/** Menue Icons - SVG */
import { ChevronDown, ShoppingCart } from 'react-feather'
/** Styles with Emotion & Tailwindcss */
import localeCheck from './utils/locale-check'

import {
	Nav,
	MenuBar,
	Button,
	CartCount,
	Triangle,
	ItemLink,
	BrandLink,
	BrandLabel,
	BrandImage,
	BrandDescription,
	BrandName,
} from './menu/styles'

/**
 * MainMenu - header navigation component
 * @see Header
 */
export default function MainMenu({ globalLocale }) {
	const { nav } = useStaticQuery(graphql`
		query getMainMenu {
			nav: allContentfulNavMenu(
				filter: { contentful_id: { eq: "3gKKAkm7N5wWvjnEkB3nkz" } }
			) {
				edges {
					node {
						node_locale
						ariaLabel
						navId
						menuItems {
							... on ContentfulNavButton {
								ariaLabel
								url
								primary
							}
							... on ContentfulNavDropDown {
								mega
								ariaLabel
								dropDownItems {
									... on ContentfulNavItem {
										title
										description
										url
										icon
										image {
											description
											file {
												url
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	`)
	/** Filter Array by Locale */
	const [{ node }] = filterLocale(nav.edges, globalLocale)
	/** Destruct Filtered Array to Node keys */
	const { navId, ariaLabel, menuItems } = node
	const { count } = useCart()
	const {
		menu: { open: menuOpen, play: menuPlay },
		toggleCart,
		toggleMenu,
	} = useTheme()
	const defaultLocale = 'en-AU'
	const checkedLocale = localeCheck(globalLocale, defaultLocale)
	const getCountry = globalLocale.split('-')[1].toLowerCase()
	return (
		<>
			<Global
				styles={css`
					.main-menu[data-reach-menu-list] {
						${tw`flex flex-col px-4 pt-4 pb-2 absolute`}
						${tw`text-black text-base shadow-xl bg-gray-100 border-gray-300 border rounded `}
						left: 50%;
						min-width: max-content;
						transform: translateY(15px) translateX(-50%);
						box-shadow: rgba(50, 50, 50, 0.1) 0px 10px 100px,
							rgba(50, 50, 50, 0.12) 0px 15px 35px,
							rgba(50, 50, 50, 0.1) 0px 5px 15px;
					}
				`}
			/>
			<Nav id={navId} role="navigation" aria-label={ariaLabel}>
				<MenuBar role="menubar" id="screens" aria-orientation="horizontal">
					{menuItems.map((n, i) => {
						if (n.primary === false) {
							return (
								<>
									{globalLocale === 'en-AU' ? (
										<Button
											key={`shop-${i}`}
											role="button"
											tabIndex={-1}
											className="cta"
											onClick={() => navigate(`${checkedLocale}/shop/`)}
											onKeyDown={() => navigate(`${checkedLocale}/shop/`)}
										>
											Shop
										</Button>
									) : (
										<Button
											key={`shop-${i}`}
											role="button"
											tabIndex={-1}
											className="cta"
											onClick={() =>
												navigate(`https://mydolphin.co.${getCountry}`)
											}
											onKeyDown={() =>
												navigate(`https://mydolphin.co.${getCountry}`)
											}
										>
											Shop
										</Button>
									)}
									<Button
										key={`book-a-demo-2`}
										role="button"
										tabIndex={-1}
										className="cta book"
										onClick={() => navigate(`${checkedLocale}/book-a-demo/`)}
										onKeyDown={() => navigate(`${checkedLocale}/book-a-demo/`)}
									>
										<span className="max">Book a Demo</span>
										<span className="min">Demo</span>
									</Button>
								</>
							)
						} else if (!n.mega) {
							return (
								<Menu key={i}>
									<MenuButton role="button" tabIndex={-1}>
										{n.ariaLabel}
										{n.dropDownItems && (
											<span aria-hidden>
												<ChevronDown />
											</span>
										)}
									</MenuButton>
									{n.dropDownItems ? (
										<MenuList className="slide-down main-menu" role="menu">
											<Triangle />
											{n.dropDownItems.map((d, i) => {
												if (d.description) {
													if (
														d.title === 'Maytronics Commercial' ||
														d.title === 'Robot Comparison'
													) {
														return (
															<MenuLink
																key={`${d.title}-${i}`}
																as={BrandLink}
																href={d.url}
																role="menuitem"
																tabIndex={-1}
															>
																<BrandImage
																	{...d.image}
																	role="img"
																	aria-label={d.image.description}
																/>
																<BrandLabel>
																	<BrandName>{d.title}</BrandName>
																	<BrandDescription>
																		{d.description}
																	</BrandDescription>
																</BrandLabel>
															</MenuLink>
														)
													} else {
														return (
															<MenuLink
																key={`${d.title}-${i}`}
																as={BrandLink}
																to={`${checkedLocale}/${d.url}`}
																role="menuitem"
																tabIndex={-1}
															>
																<BrandImage
																	{...d.image}
																	role="img"
																	aria-label={d.image.description}
																/>
																<BrandLabel>
																	<BrandName>{d.title}</BrandName>
																	<BrandDescription>
																		{d.description}
																	</BrandDescription>
																</BrandLabel>
															</MenuLink>
														)
													}
												} else {
													return (
														<MenuLink
															key={`${d.title}-${i}`}
															as={ItemLink}
															to={`${checkedLocale}/${d.url}`}
															role="menuitem"
															tabIndex={-1}
														>
															{d.title}
														</MenuLink>
													)
												}
											})}
										</MenuList>
									) : (
										''
									)}
								</Menu>
							)
						}
					})}
					{globalLocale === 'en-AU' ? (
						<Button
							onClick={() => navigate(`${checkedLocale}/shop/cart/`)}
							onKeyDown={() => navigate(`${checkedLocale}/shop/cart/`)}
							aria-label={`Shopping Cart, Items: ${count}`}
							className="cart"
							role="button"
							tabIndex={-1}
						>
							<span aria-hidden>
								<ShoppingCart />
								{count ? <CartCount>{count}</CartCount> : ''}
							</span>
						</Button>
					) : (
						''
					)}
				</MenuBar>
				<MenuBar role="menubar" id="mobile" aria-orientation="horizontal">
					<Button
						role="button"
						tabIndex={-1}
						aria-haspopup="true"
						aria-expanded={menuOpen}
						aria-controls="mobile-menu"
						aria-label="Open Mobile Menu"
						className="burger"
						onMouseDown={toggleMenu}
						onKeyDown={toggleMenu}
					>
						<Lottie
							playingState={'playing'}
							speed={1}
							direction={menuPlay ? 1 : -1}
							width="24px"
							height="24px"
							className="lottie-container menu-3"
							config={{
								animationData: animationMenu.default,
								loop: false,
								autoplay: true,
								renderer: 'svg',
							}}
							aria-hidden
						/>
					</Button>
					<Button
						onClick={() => navigate(`${checkedLocale}/shop/cart/`)}
						onKeyDown={() => navigate(`${checkedLocale}/shop/cart/`)}
						aria-label={`Shopping Cart, Items: ${count}`}
						className="cart"
						role="button"
						tabIndex={-1}
					>
						<span aria-hidden>
							<ShoppingCart />
							{count ? <CartCount>{count}</CartCount> : ''}
						</span>
					</Button>
				</MenuBar>
				<MobileMenu items={menuItems} />
			</Nav>
		</>
	)
}
