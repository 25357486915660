// @ts-check
/* eslint no-console: off*/
/**
 * @function
 * @name filterLocale
 * Filter an array of nodes by a specific i18n locale
 * @param {Array} array - The edge array of node objects
 * @param {string} locale [locale=en-AU] - i18n locale code
 * @returns {Array<Object>} array of object nodes
 */
export default function filterLocale(array, locale) {
	const i18n = locale
	try {
		if (!i18n) throw '@param {string} locale - is empty'
		if (!array) throw '@param {Array} array - is empty'
		if (!array[0].node.node_locale) throw 'array node missing key - node_locale'
		/** Array.prototype.filter() */
		return array.filter(edge => edge.node.node_locale === i18n)
	} catch (err) {
		console.error(err)
	}
}
