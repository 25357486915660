import React from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { animated, useTransition } from 'react-spring'
import useTheme from '../../hooks/theme'
import MobileMenuItem from './mobile-menu-item'

/**
 * MobileMenu - Items for the MainMenu
 * @see MainMenu
 * @param {[]} props.items - menu items array
 */
export default function MobileMenu({ items }) {
	const {
		menu: { open: menuOpen },
	} = useTheme()
	const AnimatedDialogOverlay = animated(DialogOverlay)
	const AnimatedDialogContent = animated(DialogContent)
	const transitions = useTransition(menuOpen, null, {
		from: { opacity: 0, y: -10 },
		enter: { opacity: 1, y: 0 },
		leave: { opacity: 0, y: 10 },
	})
	return (
		<>
			{transitions.map(
				({ item, key, props: styles }) =>
					item && (
						<AnimatedDialogOverlay
							key={key}
							css={{
								opacity: styles.opacity,
								position: 'absolute',
								background: 'transparent',
								top: '102px',
								height: '100%',
							}}
						>
							<AnimatedDialogContent
								role="dialog"
								aria-label="Mobile Menu"
								id="mobile-menu"
								css={{
									transform: styles.y.interpolate(
										value => `translate3d(0px, ${value}px, 0px)`
									),
									width: '100%',
									height: '100%',
									margin: 0,
									padding: '0.5rem',
								}}
							>
								{items.map((x, i) => {
									if (!x.mega && !(x.primary === false)) {
										return (
											<MobileMenuItem key={'MobileMenuItem' + i} item={x} />
										)
									}
								})}
							</AnimatedDialogContent>
						</AnimatedDialogOverlay>
					)
			)}
		</>
	)
}
MobileMenu.propTypes = {
	items: PropTypes.array,
}
