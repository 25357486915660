import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'gatsby'
import tw, { css, styled } from 'twin.macro'

import TobBar from './top-bar'
import SearchBar from './search-bar'
import MainMenu from './menu'
import Cart from './cart'
import localeCheck from './utils/locale-check'

import Maytronics from './svg/maytronics'

const MenuBar = tw.div`relative flex justify-center items-center w-full bg-white top-0 shadow-lg`
const Wrapper = tw.div`flex justify-between w-full md:w-11/12 md:justify-center items-center px-4`
const Brand = styled.div`
	${tw`flex flex-row pb-3 pr-4`}
	padding-top: 18px;
	a {
		${tw`
			no-underline
			flex-1
			flex
			flex-row
			justify-between
			items-center
		`}
		svg {
			${tw`text-dolphin-gray`}
		}
	}
`
/**
 * Main header for all pages, contains: brand + main menu + burger menu
 */
const Header = ({ globalLocale }) => {
	const [isSticky, setSticky] = useState(false)
	const ref = useRef(null)
	const handleScroll = () => {
		setSticky(
			ref && ref.current && ref.current.getBoundingClientRect().top <= 0
		)
	}
	useEffect(() => {
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', () => handleScroll)
		}
	}, [])

	const defaultLocale = 'en-AU'
	const checkedLocale = localeCheck(globalLocale, defaultLocale)
	return (
		<div css={{ zIndex: 100 }}>
			<TobBar />
			<SearchBar />
			<div css={[tw`relative`, { height: `${66}px` }]} ref={ref}>
				<MenuBar css={isSticky && tw`fixed`}>
					<Wrapper>
						<Brand>
							<Link to={`${checkedLocale}/`}>
								<Maytronics
									alt="Maytronics Australia"
									color="currentColor"
									tilde="#58C8DF"
									width="150"
									height="36"
								/>
							</Link>
						</Brand>
						<MainMenu globalLocale={globalLocale} />
					</Wrapper>
				</MenuBar>
				<Cart
					isSticky={isSticky}
					checkedLocale={checkedLocale}
					globalLocale={globalLocale}
				/>
			</div>
		</div>
	)
}

export default Header
