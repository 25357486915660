import React from 'react'
import PropTypes from 'prop-types'

const ClimateCare = props => {
	const { color, size, ...otherProps } = props
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 254.26 113.29"
			role="img"
			aria-label="Spasa Climate Care Certified"
			{...otherProps}
			{...(size && { width: size })}
		>
			<title>Spasa Climate Care Certified</title>
			<path
				d="M16.78,56.76c0,22.8,18.59,40.93,41.69,40.93A39.87,39.87,0,0,0,86.52,86.63c.92-1,.88-1.36.17-2.21l-3.18-3.6c-.72-.6-.72-1.38-2.8.31-6.12,4.56-12.31,8.24-22.36,8.24-18.24,0-32.21-15.17-32.21-32.73S39.76,24.6,58,23.47a32.88,32.88,0,0,1,18.66,4.38c6.92,4.24,6.36,4.52,7.86,3.08l2.31-2.54c.93-.92,1-1.4,0-2.31A41.88,41.88,0,0,0,58.47,15.6c-22,0-41.69,18.35-41.69,41.16"
				fill={color}
			/>
			<path
				d="M49.08,78.64c11.08,4.28,21.17.15,25.58-8.57,4.06-8.38.43-20.6-10-22.57-5-1.66.53-12.1-1.07-13.85-.67-.8-3.25-1.35-8.48.27-6.74,2.32-11.77,5.16-16.44,13.6-6,12.94.35,26.88,10.45,31.12"
				fill={color}
			/>
			<path
				d="M0,56.8c0,31.63,25.81,56.49,57.68,56.49A54.9,54.9,0,0,0,96.3,97.94c.79-.79,1.36-1.14,0-2.67l-2.43-2.65c-.62-.79-1.41-.46-2.77.62-10.06,8-19.72,13-33.42,13a49.57,49.57,0,1,1,0-99.13A50.13,50.13,0,0,1,91.3,20c1.84,1.68,2,.81,3-.13L96.76,17c.94-1,.41-1.3-.63-2.26C85.78,5.47,73.93,0,57.68,0,25.81,0,0,25.33,0,56.8"
				fill={color}
			/>
			<path
				d="M241.36,91.23V75.14h4.13c4.26,0,7.55,3.48,7.55,8s-3.29,8.07-7.55,8.07Zm-1.14.78a.32.32,0,0,0,.29.34h5.17c4.73,0,8.58-4.09,8.58-9.19S250.41,74,245.68,74h-5.17a.33.33,0,0,0-.29.34Zm-18.45,0a.33.33,0,0,0,.31.34h9.78a.33.33,0,0,0,.32-.34v-.42a.34.34,0,0,0-.32-.34h-8.92V83.5h7.73a.33.33,0,0,0,.31-.34v-.45a.33.33,0,0,0-.31-.34h-7.73V75.14h8.92a.34.34,0,0,0,.32-.34v-.45a.34.34,0,0,0-.32-.34h-9.78a.33.33,0,0,0-.31.34Zm-10.72,0a.33.33,0,0,0,.31.34h.52a.34.34,0,0,0,.34-.34V74.35a.34.34,0,0,0-.32-.34h-.54a.33.33,0,0,0-.31.34Zm-18.16,0a.33.33,0,0,0,.32.34h.44A.35.35,0,0,0,194,92l0-7.81h7.73c.37,0,.32-.47.32-.76a.34.34,0,0,0-.32-.34h-7.73v-8H203a.34.34,0,0,0,.32-.34v-.45A.34.34,0,0,0,203,74h-9.78a.34.34,0,0,0-.32.34Zm-10.72,0a.33.33,0,0,0,.32.34H183a.34.34,0,0,0,.34-.34V74.35A.33.33,0,0,0,183,74h-.54a.34.34,0,0,0-.32.34Zm-13.5,0a.33.33,0,0,0,.31.34h.44a.35.35,0,0,0,.39-.34V75.14h4.68a.32.32,0,0,0,.32-.34v-.45a.32.32,0,0,0-.32-.34H164a.32.32,0,0,0-.32.34v.39c0,.24.08.4.32.4h4.66Zm-21.54-7.83v-9h5.13a4.36,4.36,0,0,1,4.34,4.4,4.43,4.43,0,0,1-4.32,4.56ZM146,92a.33.33,0,0,0,.32.34h.44a.35.35,0,0,0,.39-.34V85.28h4.49c.31,0,.58,0,.9,0L156,92.12a.37.37,0,0,0,.35.23h.75c.25,0,.29-.26.17-.47l-3.56-6.79a5.66,5.66,0,0,0,4-5.47A5.48,5.48,0,0,0,152.33,74h-6.05a.33.33,0,0,0-.32.34Zm-18.45,0a.33.33,0,0,0,.32.34h9.78a.33.33,0,0,0,.32-.34v-.42a.34.34,0,0,0-.32-.34h-8.93V83.5h7.73a.34.34,0,0,0,.32-.34v-.45a.34.34,0,0,0-.32-.34h-7.73V75.14h8.93a.34.34,0,0,0,.32-.34v-.45a.34.34,0,0,0-.32-.34h-9.78a.34.34,0,0,0-.32.34Zm-22.78-8.8a9.08,9.08,0,0,0,8.83,9.4,8.12,8.12,0,0,0,6-2.54.31.31,0,0,0,0-.44l-.42-.45a.29.29,0,0,0-.39,0,7.46,7.46,0,0,1-5.14,2.21c-4.27,0-7.61-3.73-7.61-8.23s3.34-8.28,7.61-8.28a7.31,7.31,0,0,1,5.14,2.15c.15.15.27.18.42,0l.39-.44a.37.37,0,0,0,0-.48,8.05,8.05,0,0,0-5.92-2.43c-4.88,0-8.83,4.22-8.83,9.46"
				fill={color}
			/>
			<path
				d="M106.85,31a7.43,7.43,0,0,1,7.31-7.54,7.92,7.92,0,0,1,5,2c.26.26.47.29.68,0l.71-.71a.5.5,0,0,0,0-.73,9.2,9.2,0,0,0-6.34-2.44,9.44,9.44,0,1,0,0,18.87,9,9,0,0,0,6.37-2.54.5.5,0,0,0,0-.71l-.78-.71a.5.5,0,0,0-.63.08,7.63,7.63,0,0,1-5,2A7.42,7.42,0,0,1,106.85,31m27.78,8.68V39a.5.5,0,0,0-.5-.5h-7.54V22.36a.52.52,0,0,0-.5-.5h-1a.5.5,0,0,0-.5.5V39.71a.5.5,0,0,0,.5.5h9.06a.5.5,0,0,0,.5-.5m5.17,0V22.36a.52.52,0,0,0-.5-.5h-1.05a.52.52,0,0,0-.5.5V39.71a.52.52,0,0,0,.5.5h1.05a.52.52,0,0,0,.5-.5m24-.13L160.44,22a.5.5,0,0,0-.47-.37h-.42a.5.5,0,0,0-.45.29L154,36.33h-.13l-5.19-14.44a.49.49,0,0,0-.44-.29h-.42a.5.5,0,0,0-.47.37L144,39.58c-.08.36.08.63.47.63h1a.52.52,0,0,0,.47-.37l2.28-13.5h.1l4.88,13.84a.51.51,0,0,0,.44.29h.47a.53.53,0,0,0,.45-.29l4.82-13.84h.11l2.33,13.5a.54.54,0,0,0,.47.37h1c.39,0,.55-.27.47-.63m15-5.61H171l3.82-8.6H175c1.28,2.86,2.54,5.74,3.83,8.6m4.58,5.55L175.5,21.89a.47.47,0,0,0-.45-.29h-.26a.46.46,0,0,0-.44.29l-8,17.63a.46.46,0,0,0,.44.68h1.05a.48.48,0,0,0,.45-.28l1.91-4.33h9.3l1.94,4.33a.49.49,0,0,0,.45.28H183a.46.46,0,0,0,.44-.68m11.5-16.4v-.76a.5.5,0,0,0-.5-.5H183.3a.5.5,0,0,0-.5.5v.76a.5.5,0,0,0,.5.5h4.56V39.71a.51.51,0,0,0,.5.5h1a.52.52,0,0,0,.5-.5V23.62h4.56a.5.5,0,0,0,.5-.5m5.18,15.33V31.77h7.52a.5.5,0,0,0,.5-.5v-.76a.52.52,0,0,0-.5-.5h-7.52V23.62h8.8a.5.5,0,0,0,.5-.5v-.76a.5.5,0,0,0-.5-.5H198.57a.5.5,0,0,0-.5.5V39.71a.5.5,0,0,0,.5.5h10.32a.5.5,0,0,0,.5-.5V39a.5.5,0,0,0-.5-.5Z"
				fill={color}
			/>
			<path
				d="M108.43,57.06A5.94,5.94,0,0,1,114.27,51a6.18,6.18,0,0,1,4,1.55.42.42,0,0,0,.66,0l1.6-1.66a.48.48,0,0,0,0-.73,8.85,8.85,0,0,0-6.34-2.44,9.44,9.44,0,1,0,0,18.87A9,9,0,0,0,120.56,64a.5.5,0,0,0,0-.71L119,61.61a.5.5,0,0,0-.66,0,6.22,6.22,0,0,1-4,1.5,5.93,5.93,0,0,1-5.87-6m25.22,3h-5.27L131,54.25H131Zm6,5.58L131.63,48a.45.45,0,0,0-.44-.29h-.27a.48.48,0,0,0-.44.29L122.33,65.6a.46.46,0,0,0,.44.68h2.28a.78.78,0,0,0,.76-.53l1.29-2.83h7.83l1.29,2.83a.75.75,0,0,0,.76.53h2.28a.46.46,0,0,0,.44-.68m13-12a2.67,2.67,0,0,1-2.59,2.68h-4.27V51.08h4.27a2.6,2.6,0,0,1,2.59,2.54m3.41,0a5.67,5.67,0,0,0-5.71-5.63h-7.5a.49.49,0,0,0-.49.49V65.78a.5.5,0,0,0,.49.5h2.41a.51.51,0,0,0,.5-.5V59.1h2.91L152.2,66a.44.44,0,0,0,.42.24h2.75a.49.49,0,0,0,.44-.76l-3.59-6.66a5.77,5.77,0,0,0,3.88-5.29m7.38,9.56V58.55H170a.5.5,0,0,0,.5-.5V55.88a.51.51,0,0,0-.5-.5h-6.47v-4.3h7.76a.5.5,0,0,0,.5-.5V48.43a.49.49,0,0,0-.5-.49H160.57a.48.48,0,0,0-.49.49V65.78a.49.49,0,0,0,.49.5h10.67a.5.5,0,0,0,.5-.5V63.63a.5.5,0,0,0-.5-.5Z"
				fill={color}
			/>
		</svg>
	)
}

ClimateCare.propTypes = {
	color: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ClimateCare.defaultProps = {
	color: '#fff',
	size: '',
}

export default ClimateCare
