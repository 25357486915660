import React from 'react'
import PropTypes from 'prop-types'

const YouTube = ({ color, width, height }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 300 300"
			width={width}
			height={height}
			fill={color}
		>
			<polygon points="129.82 180.3 182.42 150.06 129.82 119.7 129.82 180.3" />
			<path d="M150,0A150,150,0,1,0,300,150,150,150,0,0,0,150,0ZM251.14,150.19s0,32.86-4.25,48.6a25.14,25.14,0,0,1-17.74,17.86c-15.87,4.13-79.21,4.13-79.21,4.13s-63.1,0-79.09-4.25A25.73,25.73,0,0,1,53,198.66c-4.13-15.61-4.13-48.6-4.13-48.6s0-32.86,4.13-48.72a26.13,26.13,0,0,1,17.86-18c15.87-4.13,79.09-4.13,79.09-4.13s63.34,0,79.21,4.38a25,25,0,0,1,17.74,17.74C251.26,117.2,251.14,150.19,251.14,150.19Z" />
		</svg>
	)
}

YouTube.propTypes = {
	color: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
}
YouTube.defaultProps = {
	color: 'currentColor',
	width: '24',
	height: '24',
}
export default YouTube
