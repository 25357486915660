import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

import tw, { css, styled } from 'twin.macro'
/** useCart Hook */
import useCart from '../hooks/useCart'
/** Image imports */
import x48 from '../images/48.png'
import { PlusSquare, MinusSquare } from 'react-feather'
/** CSS - Tailwind & Linaria */
const Item = styled.div`
  ${tw`flex flex-row px-2 bg-transparent hover:bg-gray-400 py-2 rounded w-full`}
	margin-top: 2px;
  transition: background-color 0.2s 0.3s ease-in-out;
  &:hover {
    .image {
      ${tw`bg-gray-100`}
    }
  }
  .image {
    ${tw`bg-gray-100 mb-0 mr-1 flex flex-col`}
    transition: background-color 0.2s 0.3s ease-in-out;
    border-radius: 8px;
    img {
      opacity: 0;
      margin: 0;
      width: 84px;
    }
  }
  .information {
    ${tw`flex flex-col flex-1`}
    .name {
      ${tw`text-base font-bold font-display text-black hover:text-blue-500 cursor-pointer`}
    }
    .item-details {
      ${tw`flex flex-row h-full justify-between`}
      .details {
        ${tw`flex flex-col w-2/3`}
        .sku {
          ${tw`text-xs text-gray-700`}
        }
        .price {
          ${tw`text-base font-normal text-gray-800 mt-auto`}
        }
      }
      .quantity {
        ${tw`flex items-center self-end text-gray-800 w-1/3`}
        input[name="quantity"] {
          transition: background-color 0.2s 0.3s ease-in-out;
          ${tw`w-8 text-center `}
          ${tw`border-solid rounded border-2 px-1 pt-px font-normal cursor-pointer`}
          ${tw`border-gray-800 hover:bg-gray-400`}
        }
        .plus,
        .minus {
          ${tw`text-gray-800 cursor-pointer`}
        }
        .plus {
          ${tw``}
        }
        .minus {
          ${tw``}
        }
      }
    }
  }
`
const Image = styled.figure`
	background-image: url(${props =>
		`${props.img?.split('?') &&
			props.img?.split('?')[0]}?fm=png&fl=png8&fit=pad&w=256&h=256`});
	transition: background-color 0.2s 0.3s ease-in-out;
	${tw`bg-contain bg-gray-400 bg-no-repeat bg-center`}
`
const LineItems = ({ confirmed }) => {
	const { items, incrementInCart, decrementInCart } = useCart()
	if (!items) {
		return (
			<small>
				No Items{' '}
				<span role="img" aria-label="sad face emoji">
					😢
				</span>
			</small>
		)
	} else {
		return (
			<>
				{items.map((item, index) => {
					const { img, name, slug, price, sku, quantity } = item
					return (
						<Item key={sku + index}>
							<Image className="image" {...item}>
								<img src={x48} alt="product name" />
							</Image>
							<div className="information">
								<Link to={`/product/${slug}`} className="name">
									{name}
								</Link>
								<div className="item-details">
									<div className="details">
										<span className="sku">{sku}</span>
										<div className="price">
											{Number(price / 100).toLocaleString('en-AU', {
												style: 'currency',
												currency: 'AUD',
											})}
										</div>
									</div>
									<div className="quantity" hidden={confirmed}>
										<button
											className="minus"
											hidden={confirmed}
											onClick={event => decrementInCart(index)}
											onKeyPress={event => decrementInCart(index)}
										>
											<MinusSquare />
										</button>
										<input
											name="quantity"
											type="text"
											value={quantity}
											onChange={event => event}
											hidden={confirmed}
										/>
										<button
											className="plus"
											hidden={confirmed}
											onClick={event => incrementInCart(index)}
											onKeyPress={event => incrementInCart(index)}
										>
											<PlusSquare />
										</button>
									</div>
								</div>
							</div>
						</Item>
					)
				})}
			</>
		)
	}
}
LineItems.defaultProps = {
	confirmed: false,
}
LineItems.propTypes = {
	confirmed: PropTypes.bool,
}
export default LineItems
