// @ts-check
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
// import './fonts/flexo.css'
import './fonts/magistral.css'
import './fonts/Inter/inter.css'
import tw, { css, styled } from 'twin.macro'
/** Layout Components */
import Header from './header'
import Footer from './footer'
/** Global Styles */
import GlobalStyles from './utils/global.js'
import useLocale from '../hooks/useLocale'
const windowGlobal = typeof window !== 'undefined' && window
/** Local Styles */
const Wrapper = tw.div`flex flex-col flex-1 overflow-hidden relative`
const Page = tw.main`relative flex flex-col`
/**
 * Layout - Wrapping your pages in top level goodness
 * @param {Object} props
 * @param {Node} props.children
 */
const Layout = ({ children }) => {
	const [pathname, setPathName] = useState('')
	useEffect(() => {
		const path = windowGlobal.location.pathname
		setPathName(path)
	}, [])
	const { globalLocale } = useLocale(pathname)
	return (
		<Wrapper>
			<GlobalStyles />
			<Header globalLocale={globalLocale} />
			<Page>{children}</Page>
			<Footer locale={globalLocale} />
		</Wrapper>
	)
}
Layout.propTypes = {
	children: PropTypes.node.isRequired,
}
export default Layout
