import { useContext } from 'react'
import { navigate } from '@reach/router'

import { OktaContext } from '../context/okta'

const isWindow = typeof window !== 'undefined'
const localStorage = isWindow && window.localStorage

const useOkta = () => {
	const { okta } = useContext(OktaContext)

	const isAuthenticated = () => {
		if (isWindow) {
			return localStorage.getItem('isAuthenticated') === 'true'
		} else {
			return false
		}
	}

	const setIsAuthenticated = boolean => {
		if (!isWindow) return
		return localStorage && localStorage.setItem('isAuthenticated', boolean)
	}

	const logOut = async () => {
		await okta.authClient.signOut()
		await okta.authClient.tokenManager.clear()
		localStorage && localStorage.setItem('isAuthenticated', 'false')
		return navigate('/account/login')
	}

	return { isAuthenticated, setIsAuthenticated, okta, logOut }
}

export default useOkta
