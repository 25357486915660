import React from 'react'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'

import { connectSearchBox } from 'react-instantsearch-dom'
/** Icon */
import Search from '../svg/search'
/** Styled Components */
const Form = styled.form`
	${tw`relative flex w-full`}
`
const Input = styled.input`
	transition: all 0.1s ease-in !important;
	${tw`flex w-full appearance-none leading-normal py-2 pr-4 pl-10`}
	${tw`bg-gray-400 focus:bg-white`}
	${tw`focus:shadow-outline focus:outline-none`}
	${tw`border-2 border-gray-700 focus:border-gray-500 rounded`}
	&::placeholder {
		${tw`text-gray-700`}
	}
	&:focus {
		box-shadow: 0 0 0 3px rgba(226, 232, 240, 0.25);
	}
`
const Icon = styled.div`
	${tw`pointer-events-none absolute inset-y-0 left-0 pl-4 flex items-center`}
`
/** SearchBox - Search Form Component for React Instant Search */
const SearchBox = ({ currentRefinement, refine, ...rest }) => (
	<Form noValidate action="" role="search">
		<Icon>
			<Search size="1rem" />
		</Icon>
		<Input
			type="search"
			placeholder={`Search the site (Type to focus)`}
			aria-label="Search"
			value={currentRefinement}
			onChange={event => refine(event.currentTarget.value)}
			{...rest}
		/>
	</Form>
)
SearchBox.propTypes = {
	currentRefinement: PropTypes.any,
	refine: PropTypes.any,
}
const CustomSearchBox = connectSearchBox(SearchBox)
export default CustomSearchBox
