// @ts-check
import React from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from 'gatsby'
import { animated, useTransition } from 'react-spring'
import tw, { css, styled } from 'twin.macro'
import { X } from 'react-feather'
/** Hook in to contextAPI */
import useCart from '../hooks/useCart'
import useTheme from '../hooks/theme'
/** Cart Components */
import LineItems from './line-items'
import getCurrency from './utils/currency'
/** CSS - Tailwind & Linaria */
const SideCart = styled.div`
	${tw`flex flex-col justify-start h-screen right-0 absolute bg-gray-400 p-1`}
	width: 325px;
	&.sticky {
		position: fixed;
		top: 67px;
	}
	.cart-bar {
		${tw`flex justify-between items-center p-2`}
		.title {
			${tw`text-lg text-gray-800 font-extrabold font-display`}
		}
		.close {
			${tw`flex bg-gray-500 hover:bg-gray-100 cursor-pointer rounded`}
			${tw`text-gray-800 hover:text-gray-600 text-base`}
		}
		.line-items {
			${tw`flex flex-col`}
		}
	}
`
const Finalise = styled.nav`
	${tw`flex flex-col h-full justify-start py-4 px-2`}
	margin-top: 2px;
`
const Total = styled.div`
	${tw`flex px-4 pb-2 justify-between text-gray-800 text-lg font-normal`}
	b {
		${tw`text-gray-800 text-lg font-semibold`}
	}
`
const Checkout = styled.button`
	${tw`mt-4 flex bg-blue-500 hover:bg-blue-400 text-white rounded justify-center items-center text-lg font-sans py-2 cursor-pointer shadow`}
`
/**
 * Cart component
 */
const Cart = ({ isSticky, checkedLocale, globalLocale }) => {
	const { total } = useCart()
	const {
		cart: { open: cartOpen },
		closeCart,
	} = useTheme()
	const AnimatedSideCart = animated(SideCart)
	const transitions = useTransition(cartOpen, null, {
		from: { opacity: 0, x: 325 },
		enter: { opacity: 1, x: 0 },
		leave: { opacity: 0, x: -325 },
	})

	const currency = getCurrency(globalLocale)
	return (
		<div css={{ position: 'relative' }}>
			{transitions.map(
				({ item, key, props: styles }) =>
					item && (
						<AnimatedSideCart
							key={key}
							id="cart"
							css={{
								opacity: styles.opacity,
								transform: styles.x.interpolate(
									value => `translateX(${value}px)`
								),
							}}
							className={isSticky && 'sticky'}
						>
							<nav
								className="cart-bar"
								id="cart-menu"
								role="navigation"
								aria-label="cart menu"
							>
								<span className="title">Mini Cart</span>
								<button
									aria-label="Close cart"
									className="close"
									onClick={closeCart}
									onKeyPress={closeCart}
								>
									<X aria-hidden css={tw`w-6 h-6`} />
								</button>
							</nav>
							<div className="line-items">
								<LineItems />
							</div>
							<Finalise>
								<Total>
									<span>Total</span>
									<b>
										{Number(total / 100).toLocaleString(globalLocale, {
											style: 'currency',
											currency: currency,
										})}
									</b>
								</Total>
								<Checkout
									onClick={() => {
										closeCart()
										return navigate(`${checkedLocale}/shop/cart`)
									}}
									role="button"
								>
									<span>View cart</span>
								</Checkout>
							</Finalise>
						</AnimatedSideCart>
					)
			)}
		</div>
	)
}
Cart.propTypes = {
	isSticky: PropTypes.bool,
}
export default Cart
