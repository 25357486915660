/* eslint-disable jsx-a11y/role-supports-aria-props */
import React, { useState, useRef, useEffect } from 'react'
import { navigate } from 'gatsby'
import PropTypes from 'prop-types'
import tw, { css, styled } from 'twin.macro'

import { ChevronDown } from 'react-feather'
import useTheme from '../../hooks/theme'
import {
	Button,
	BrandLabel,
	BrandImage,
	BrandDescription,
	BrandName,
} from './styles'

const MobileButton = styled(Button)`
	${tw`w-full border-b-2 border-gray-600 hover:bg-gray-300`}
	${tw`text-lg`}
	&[aria-expanded="true"] {
		${tw`bg-gray-300`}
	}
	svg {
		${tw`ml-auto bg-gray-500 rounded`}
	}
`
const MobileItemLink = styled.button`
	${tw`flex w-full pl-4 text-gray-700 hover:text-gray-800 my-2`}
`
const MobileBrandLink = styled.button`
	${tw`flex items-center w-full rounded bg-gray-500 text-gray-800 hover:text-gray-700 pr-1 my-1`}
`
/**
 * MobileMenuItem - Items for the MobileMenu
 * @see MobileMenu
 * @param {Object} props.item - menu item object
 */
export default function MobileMenuItem({ item }) {
	const { closeMenu } = useTheme()
	const [isExpanded, setIsExpanded] = useState(false)
	const toggle = () => setIsExpanded(!isExpanded)
	const items = item.dropDownItems
	const ref = useRef(null)
	useEffect(() => {
		const listener = event => {
			if (!ref.current || ref.current.contains(event.target)) {
				return
			}
			return setIsExpanded(false)
		}
		document.addEventListener('mouseup', listener)
		document.addEventListener('keyup', listener)
		return () => {
			document.removeEventListener('mouseup', listener)
			document.removeEventListener('keyup', listener)
		}
	}, [ref, setIsExpanded])
	return (
		<div ref={ref}>
			<MobileButton
				role="menuitem"
				aria-expanded={isExpanded}
				aria-haspopup={true}
				className="mobile-menuitem"
				onClick={toggle}
				onKeyDown={toggle}
			>
				{item.ariaLabel}
				{item.dropDownItems && <ChevronDown size="24" aria-hidden />}
			</MobileButton>
			<div
				role="menu"
				className="mobile-submenu"
				aria-hidden={!isExpanded}
				hidden={!isExpanded}
				css={tw`border-b-2 border-gray-600`}
			>
				{items.map((item, index) => {
					const checkUrl = (data) => {
						const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
						return urlPattern.test(data) ? data : `/${data}`
					}

					if (item.description) {
						return (
							<MobileBrandLink
								key={item.title}
								role="menuitem"
								onClick={() => {
									closeMenu()
									navigate(checkUrl(item.url));
								}}
							>
								<BrandImage
									{...item.image}
									role="img"
									aria-label={item.image.description}
								/>
								<BrandLabel>
									<BrandName>{item.title}</BrandName>
									<BrandDescription>{item.description}</BrandDescription>
								</BrandLabel>
							</MobileBrandLink>
						)
					} else {
						return (
							<MobileItemLink
								key={item.title}
								role="menuitem"
								onClick={() => {
									closeMenu()
									navigate(checkUrl(item.url))
								}}
							>
								{item.title}
							</MobileItemLink>
						)
					}
				})}
			</div>
		</div>
	)
}
MobileMenuItem.propTypes = {
	item: PropTypes.object,
}
