export default function getCurreny( locale ) {
    switch (locale) {
        case 'en-AU':
            return 'AUD'
        case 'en-NZ':
            return 'NZD'
        case 'en-ZA':
            return 'ZAR'
        break
    }
}
