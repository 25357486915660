import { useContext, useEffect } from 'react'
import { LocaleContext } from '../context/localeContext'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { CartContext } from '../context/CartContext'

const useLocale = (pathname, pathhash, pathsearch) => {
    const [locale, setLocale] = useContext(LocaleContext)
    const [cart, setCart] = useContext(CartContext)
    const locales = ["en-AU", "en-NZ", "en-ZA"]
    const pathLocale = pathname.split('/')
    const loadLocale = pathLocale[1]
    const initialLocale = locales.indexOf(loadLocale)
    const x = pathname.split(locales[initialLocale])
    const globalLocale = locale
    const { pages } = useStaticQuery(graphql`
        {
        pages:allSitePage {
                edges {
                    node {
                        path
                    }
                }
            }
        }
    `)
    const changeLocale = (newlocale) => {
        setCart({
            lineItems: [],
            paymentMethod: {
                id: '',
                billing_details: {},
            },
        })
        const splitLocales = locales.map((locale, i) => {
            const loc = locale.split('-')
            return loc[1]
        })
        const check = splitLocales.indexOf(newlocale)
        if ( check === 0 ) {
            const pagex = pages.edges.filter((page, i) => {
                if ( x.length > 1 ) {
                    if ( page.node.path === x[1] ) {
                        return page
                    }
                    if ( page.node.path === `${x[1]}/`) {
                        return page
                    }
                    if ( x[1] === `${page.node.path}/`) {
                        return page
                    }
                } else {
                    if ( page.node.path === x[0] ) {
                        return page
                    }
                    if ( page.node.path === `${x[0]}/`) {
                        return page
                    }
                    if ( x[0] === `${page.node.path}/`) {
                        return page
                    }
                }
            })
            if ( pagex.length > 0 ) {
                navigate(`${pagex[0].node.path}${pathsearch != '' ? pathsearch : ''}${pathhash != '' ? pathhash : ''}`)
            } else {
                navigate('/')
            }
        } else if ( check >= 1 ) {
            const pagex = pages.edges.filter((page, i) => {
                if ( x.length > 1 ) {
                    // console.log(page.node.path)
                    // console.log(`/${locales[check]}${x[1]}`)
                    if ( `${page.node.path}` === `/${locales[check]}${x[1]}`) {
                        return page
                    }
                    if ( `${page.node.path}` === `/${locales[check]}${x[1]}/`) {
                        return page
                    }
                } else {
                    if ( `/${locales[check]}${x[0]}` === page.node.path) {
                        return page
                    }
                    if ( `/${locales[check]}${x[0]}/` === page.node.path) {
                        return page
                    }
                    if ( `${page.node.path}/` === `/${locales[check]}${x[0]}` ) {
                        return page
                    }
                }
            })
            if ( pagex.length > 0 ) {
                navigate(`/${locales[check]}/${x.length > 1 ? x[1].slice(1) : x[0].slice(1)}${pathsearch != '' ? pathsearch : ''}${pathhash != '' ? pathhash : ''}`)
            } else {
                navigate(`/${locales[check]}/`)
            }
        }
    }
    useEffect(() => {
        if ( initialLocale >= 0 ) {
            setLocale(locales[initialLocale])
        } else {
            setLocale('en-AU')
        }
    }, [pathname])
    return {
        globalLocale,
        changeLocale
    }
}
export default useLocale