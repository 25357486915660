/** Styles with Emotion & Tailwindcss */
import { Link } from 'gatsby'
import tw, { css, styled } from 'twin.macro'

export const Nav = styled.nav`
	${tw`flex flex-1 justify-end`}
`
export const MenuBar = styled.div`
	${tw`hidden md:flex justify-between md:justify-center items-center text-xs`}
	&#mobile {
		${tw`flex md:hidden`}
	}
	[data-reach-menu-button] {
		${tw`flex flex-row justify-start items-center relative px-3 py-4`}
		${tw`text-gray-600 hover:text-gray-800 cursor-pointer`}
		${tw`font-sans text-base leading-none md:font-normal`}
		span {
			${tw`pl-1`}
			svg {
				${tw`stroke-current w-5 h-5`}
			}
		}
		@media screen and (max-width:1069px) {
			${tw`px-2 text-sm`}
		}
	}
`
export const Button = styled.button`
		${tw`flex flex-row justify-start items-center relative p-3`}
		${tw`text-gray-600 hover:text-gray-800 cursor-pointer`}
		${tw`font-sans text-base leading-none font-normal`}
		span {
			${tw`pl-1`}
			svg {
				${tw`stroke-current relative w-6 m-0`}
			}
		}
		@media screen and (max-width:1069px) {
			${tw`p-2 text-sm`}
		}
		&.cart {
			${tw`relative py-3`}
			span {
				${tw`pl-0`}
				svg {
					${tw`relative w-6 m-0`}
				}
			}
			@media screen and (min-width:940px) {
				position: absolute;
				right: 10px;
			}
		}
		&.cta {
			${tw`flex rounded py-2 px-2 mx-2 bg-yellow-500 text-gray-700 hover:bg-yellow-400 hover:text-gray-800 font-semibold`}
			&.book {
				.min {
					display: none;
				}
				.max {
					display: block;
				}
				@media screen and (max-width:1069px) {
					.min {
						display: block;
					}
					.max {
						display: none;
					}
				}
			}
		}
		&.burger {
			.lottie-container {
				${tw`w-8 text-gray-700 hover:text-gray-800`}
				svg {
					${tw`stroke-current`}
					path {
						${tw`stroke-current`}
					}
				}
			}
		}
`
export const CartCount = styled.span`
	${tw`flex justify-center items-center text-center absolute rounded-full bg-blue-500 text-white text-xs top-0 w-4 h-4`}
	padding: 0!important;
	right: 0.25rem;
`
export const Triangle = styled.div`
	${tw`w-full absolute bg-transparent overflow-hidden`}
	padding-top: 19px;
	top: 0;
	height: 19px;
	left: 50%;
	transform: translateY(-100%) translateX(-50%);
	&::before {
		${tw`absolute bg-gray-100`}
		content: '';
		box-shadow: 0 0 0 1px #d2d6dc;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		transform: translateX(-50%) rotate(45deg);
		border-radius: 3px;
	}
`
export const ItemLink = styled(Link)`
	${tw`flex w-full pl-4 text-gray-600 my-2`}
	&[data-selected] {
		background: unset;
		color: unset;
		outline: unset;
	}
`
export const BrandLink = styled(Link)`
	${tw`flex w-full rounded bg-gray-300 text-gray-700 p-0 pr-1 my-1`}
	&[data-selected] {
		background: unset;
		color: unset;
		outline: unset;
	}
`
export const BrandImage = styled.div`
	background-image: url(${img => img.file.url});
	${tw`border-2 rounded-l border-gray-200 w-12 h-12 bg-gray-200 bg-contain bg-no-repeat bg-center`}
`
export const BrandLabel = tw.div`flex flex-col justify-center pl-2`
export const BrandName = tw.span`text-base font-semibold font-display`
export const BrandDescription = tw.span`text-sm font-light font-sans`
