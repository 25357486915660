import React, { createContext, useEffect, useState } from 'react'

const isWindow = typeof window !== 'undefined'
const localStorage = isWindow && window.localStorage
const location = isWindow && window.location
const uri = (location && location.origin) || 'https://maytronics.sh/'
const config = {
	baseUrl: 'https://dev-868555.okta.com',
	redirectUri: uri + '/account/login',
	clientId: '0oa2dmn7yiG61cWqQ357',
	el: '#signIn',
	authParams: {
		issuer: 'https://dev-868555.okta.com/oauth2/default',
		pkce: true,
		responseType: ['token', 'id_token'],
		scopes: ['openid', 'email', 'profile'],
		// display: 'page',
	},
	features: {
		registration: true,
	},
}
export const OktaContext = createContext({
	okta: {},
})
export const OktaProvider = ({ children }) => {
	const [okta, setOkta] = useState(null)
	useEffect(() => {
		if (!isWindow) return
		var OktaSignIn = require('@okta/okta-signin-widget')
		return setOkta(new OktaSignIn(config))
	}, [setOkta])
	useEffect(() => {
		const authClient = okta && okta.authClient
		if (!isWindow || !authClient) return
		const signIn = async () => {
			const authClient = okta.authClient
			const session = await authClient.session.get()
			const accessToken = await authClient.tokenManager.get('accessToken')
			const token = accessToken && accessToken.accessToken
			// setUser(idToken && idToken.profile)
			const ACTIVE = session && session.status === 'ACTIVE'
			const AUTHTOKEN = token ? true : false
			if (ACTIVE && !AUTHTOKEN) {
				location.hash = ''
				const tokens = await authClient.token.getWithoutPrompt({
					scopes: ['openid', 'email', 'profile'],
				})
				await tokens.forEach(token => {
					if (token.idToken) {
						authClient.tokenManager.add('idToken', token)
					}
					if (token.accessToken) {
						authClient.tokenManager.add('accessToken', token)
					}
				})
				localStorage && localStorage.setItem('isAuthenticated', 'true')
				return location.reload()
			} else if (AUTHTOKEN && ACTIVE) {
				return
			} else {
				okta.remove()
			}
		}
		signIn()
	})
	return (
		<OktaContext.Provider value={{ okta }}>{children}</OktaContext.Provider>
	)
}
