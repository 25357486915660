import React, { useRef, useEffect } from 'react'
import algoliasearch from 'algoliasearch/lite'
import { Link } from 'gatsby'
import {
	InstantSearch,
	Index,
	Configure,
	Hits,
	Highlight,
	connectStateResults,
} from 'react-instantsearch-dom'
import tw, { css, styled } from 'twin.macro'
import posed from 'react-pose'
import CustomSearchBox from './search/input'
import useSearch from '../hooks/search'
import Algolia from './svg/algolia'
/** Styles with Emotion, Pose & Tailwindcss */
const Header = tw.header`flex flex-row w-full justify-center items-center bg-gray-800 text-gray-600`
const Wrapper = styled.div`
	${tw`flex flex-row w-full justify-center items-center px-6 py-2`}
	>div {
		${tw`flex flex-row w-full justify-center items-center`}
	}
`
const Bar = posed(Header)({
	closed: { height: 0, opacity: 0 },
	open: { height: 'auto', opacity: 1 },
})
const SearchWrapper = styled.div`
	${tw`flex flex-row w-full sm:w-4/5 lg:w-3/5 xxl:w-2/5 max-w-xl justify-center items-center text-base relative`}
`
const SearchResults = styled.div`
	${tw`flex flex-col bg-white rounded absolute px-6 pt-4 pb-2 border-gray-500 border top-0`}
	${tw`text-black font-bold text-base shadow-xl cursor-auto opacity-0 invisible pointer-events-none whitespace-nowrap`}
	transform: translateY(25px) translateX(-50%);
	left: 50%;
	transition: all 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.05s;
	min-width: 230px;
	z-index: unset;
	&[aria-hidden='false'] {
		opacity: 1;
		visibility: visible;
		pointer-events: all;
		transform: translateY(55px) translateX(-50%);
		z-index: 20;
	}
	h2 {
		${tw`font-display font-extrabold text-base mb-1`}
		${tw`text-gray-700`}
	}
	h4 {
		${tw`font-sans font-normal text-sm my-1 whitespace-pre-wrap`}
		${tw`text-dolphin-gray`}
	}
	hr {
		${tw`border-t border-gray-400 m-0`}
	}
	ul {
		${tw`mb-2`}
		li {
			${tw`px-2 flex md:w-56 w-64`}
			&:hover {
				${tw`bg-gray-300`}
			}
			span {
				overflow-wrap: break-word;
				word-wrap: break-word;
				hyphens: auto;
			}
		}
	}
	small {
		${tw`flex p-0 text-gray-600 font-normal`}
	}
`
const Triangle = styled.div`
	${tw`absolute overflow-hidden`}
	padding-top: 15px;
	top: 0px;
	width: 100px;
	height: 15px;
	left: 50%;
	background-color: transparent;
	transform: translateY(-100%) translateX(-50%);
	&::before {
		content: '';
		position: absolute;
		z-index: 990;
		box-shadow: 0 0 0 1px rgba(184, 194, 204, 1);
		background-color: #ffffff;
		top: 50%;
		left: 50%;
		width: 20px;
		height: 20px;
		transform: translateX(-50%) rotate(45deg);
		border-radius: 3px;
	}
`
const SearchItem = styled.li`
	${tw`m-0 py-2 text-sm`}
	a {
		${tw`text-gray-700 hover:text-dolphin-gray`}
	}
`
const PoweredBy = styled.span`
	${tw`flex self-end text-sm text-right p-0 text-gray-600 font-normal`}
	a {
		color: #5468ff;
		${tw`flex`}
	}
`
const searchClient = algoliasearch(
	process.env.GATSBY_ALGOLIA_APP_ID,
	process.env.GATSBY_ALGOLIA_SEARCH_KEY
)
const searchIndices = [
	{ name: `Pages`, title: `Pages`, hitComp: `PageHit` },
	{ name: `Posts`, title: `Posts`, hitComp: `PostHit` },
	{ name: `instant_search`, title: `Products`, hitComp: `ProductHit` },
]
const HitPage = clickHandler => ({ hit }) => {
	return (
		<Link to={'/' + hit.slug} onClick={clickHandler}>
			<h4>
				<Highlight attribute="title" hit={hit} tagName="mark" />
			</h4>
		</Link>
	)
}
const HitPost = clickHandler => ({ hit }) => {
	return (
		<Link to={`/${hit.slug}/`} onClick={clickHandler}>
			<h4>
				<Highlight attribute="title" hit={hit} tagName="mark" />
			</h4>
		</Link>
	)
}
const HitProduct = clickHandler => ({ hit }) => {
	return (
		<Link to={`/product/${hit.url}`} onClick={clickHandler}>
			<h4>
				<Highlight attribute="name" hit={hit} tagName="mark" />
			</h4>
		</Link>
	)
}
const Results = connectStateResults(
	({ searchState: state, searchResults: res, children }) =>
		res && res.nbHits > 0 ? (
			children
		) : (
			<small>No results for ❝{state.query}❞</small>
		)
)
/**
 * 'SEARCH! ...your feelings, you know it to be true...'
 * @param prop - props
 */
export default function SearchBar() {
	const ref = useRef()
	const [{ open, focus }, dispatch] = useSearch()
	useEffect(() => {
		const listener = event => {
			if (!ref.current || ref.current.contains(event.target)) {
				// console.log('inside ref event', event.target)
				return
			}
			// console.log('outside ref event', event.target)
			document.removeEventListener('mousedown', listener)
			document.removeEventListener('touchstart', listener)
			return dispatch({ type: 'setFocus', focus: false })
		}
		if (open) {
			document.addEventListener('mousedown', listener)
			document.addEventListener('touchstart', listener)
		}
		return () => {
			document.removeEventListener('mousedown', listener)
			document.removeEventListener('touchstart', listener)
		}
	}, [ref, focus, open, dispatch])
	if (!open) {
		return <></>
	}
	return (
		<Bar
			pose={open ? 'open' : 'closed'}
			aria-hidden={!open}
			css={!open ? { visibility: 'collapse' } : { visibility: 'visible' }}
		>
			<Wrapper>
				<InstantSearch
					indexName="Posts"
					searchClient={searchClient}
					onSearchStateChange={({ query }) =>
						dispatch({ type: 'setQuery', query })
					}
				>
					<SearchWrapper ref={ref} aria-haspopup={true} aria-label="search">
						<CustomSearchBox
							onFocus={() => dispatch({ type: 'setFocus', focus: true })}
						/>
						<SearchResults
							role="menu"
							aria-hidden={!focus}
							aria-label="submenu"
						>
							<Triangle />
							<Index indexName="instant_search">
								<h2>Products</h2>
								<hr />
								<Configure hitsPerPage={4} />
								<Results>
									<Hits
										hitComponent={HitProduct(() =>
											dispatch({
												type: 'setState',
												state: { focus: false, open: false, play: false },
											})
										)}
									/>
								</Results>
							</Index>
							<Index indexName="Pages">
								<h2>Pages</h2>
								<hr />
								<Configure hitsPerPage={4} />
								<Results>
									<Hits
										hitComponent={HitPage(() =>
											dispatch({
												type: 'setState',
												state: { focus: false, open: false, play: false },
											})
										)}
									/>
								</Results>
							</Index>
							<Index indexName="Posts">
								<h2>Posts</h2>
								<hr />
								<Configure hitsPerPage={4} />
								<Results>
									<Hits
										hitComponent={HitPost(() =>
											dispatch({
												type: 'setState',
												state: { focus: false, open: false, play: false },
											})
										)}
									/>
								</Results>
							</Index>
							<PoweredBy>
								Powered by &nbsp;
								<a
									href="https://algolia.com"
									target="_blank"
									rel="noreferrer noopener"
								>
									<Algolia size="1rem" /> &nbsp;Algolia
								</a>
							</PoweredBy>
						</SearchResults>
					</SearchWrapper>
				</InstantSearch>
			</Wrapper>
		</Bar>
	)
}
