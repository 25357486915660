import { useContext } from 'react'
import { ThemeContext } from '../context/theme'
export default function useOkta() {
	const [state, dispatch] = useContext(ThemeContext)
	const { theme, locale, menu, cart, signIO } = state
	const openMenu = () => dispatch({ type: 'OPEN_MENU', menu })
	const closeMenu = () => dispatch({ type: 'CLOSE_MENU', menu })
	const toggleMenu = () =>
		dispatch({ type: menu.open ? 'CLOSE_MENU' : 'OPEN_MENU', menu })
	const toggleSubMenu = () =>
		dispatch({ type: menu.sub ? 'CLOSE_SUBMENU' : 'OPEN_SUBMENU', menu })
	const openCart = () => dispatch({ type: 'OPEN_CART', cart })
	const closeCart = () => dispatch({ type: 'CLOSE_CART', cart })
	const toggleCart = () =>
		dispatch({ type: cart.open ? 'CLOSE_CART' : 'OPEN_CART', cart })
	const toggleSignIO = () =>
		dispatch({
			type: signIO.open ? 'CLOSE_SIGNIO' : 'OPEN_SIGNIO',
			signIO,
		})
	const openSignIO = () => dispatch({ type: 'OPEN_SIGNIO', signIO })
	const closeSignIO = () => dispatch({ type: 'CLOSE_SIGNIO', signIO })
	return {
		theme,
		locale,
		menu,
		openMenu,
		closeMenu,
		toggleMenu,
		toggleSubMenu,
		cart,
		openCart,
		closeCart,
		toggleCart,
		signIO,
		openSignIO,
		closeSignIO,
		toggleSignIO,
		state,
		dispatch,
	}
}
